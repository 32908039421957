document.addEventListener('turbo:load', function() {
    const settingsMenu = document.querySelector('nav ul li:nth-child(3)');
    const submenu = settingsMenu.querySelector('.submenu');
    let hideTimeout;

    settingsMenu.addEventListener('mouseenter', function() {
        // マウスが乗ったらすぐに表示し、隠すためのタイマーをクリア
        submenu.classList.remove('hidden');
        clearTimeout(hideTimeout);
    });

    settingsMenu.addEventListener('mouseleave', function() {
        // マウスが離れたときに2秒後に隠す
        hideTimeout = setTimeout(function() {
            submenu.classList.add('hidden');
        }, 2000);
    });
});
